import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword } from "firebase/auth";
import { MdErrorOutline } from "react-icons/md";
import { auth } from "../../../../config/firebase";
import { AuthContext } from "../../../../contexts/AuthContext";
import { Container } from "./style";

export function LoginADM() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const { dispatch } = useContext(AuthContext);

  async function LoginUser(event) {
    event.preventDefault();
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user
        dispatch({ type: "LOGIN", payload: user });
        navigate("/adm/add-products");
      })
      .catch((error) => {
        console.log(error.message)
        setError(true);
      });
  }

  return (

    <Container >

      <form

     className="form" onSubmit={(event) => LoginUser(event)}>

        <h2>
          <strong>Login</strong>
        </h2>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          placeholder="you user here"
          required
          autoComplete="username"
          onChange={(event) => setEmail(event.target.value)}
          value={email}
        />
        <label htmlFor="senha">Password</label>
        <input
          name="senha"
          type="password"
          placeholder="your password here"
          required
          autoComplete="current-password"
          onChange={(event) => setPassword(event.target.value)}
          value={password}
          />
        <button type="submit">Sign In</button>
        {error && (
          <div className="error">
            <MdErrorOutline color="#b72323" />
            <p>
              {" "}
              usuário ou senha incorreta
            </p>
          </div>
        )}
      </form>
    </Container>
  );
}
