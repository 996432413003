import styled from 'styled-components'
import { darken } from 'polished'
export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, auto));
  gap: 1.5rem;
  justify-content: center;
  @media (max-width: 500px) {
    grid-template-columns: 1;
  }

  .snack {
    position: relative;
    width: 100%;
    max-width: 300px;
    position: relative;
    background: ${({ theme }) => theme.colors.black};
    padding: 1.75rem 1.5rem;
    border-radius: 4px;
    .servers-weight {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 1rem;

      p {
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.9);
        padding: 0.5rem;
        color: gray;
        border-radius: 5px;
      }
    }
    span {
      position: absolute;
      top: -0.5rem;
      left: -0.5rem;

      background: ${({ theme }) => theme.colors.red};
      width: 2rem;
      height: 2rem;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: 500;
      font-size: 1.125rem;
    }

    h2 {
      margin-bottom: 0.75rem;
      font-weight: 700;
      font-size: 1rem;
      text-align: center;
    }

    .ContainerScrollView {
      width: 100% !important;
      max-width: 250px;

      .buttons {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center!important;
        color: #e7e7e7;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;

        .bullets {
          margin-top: -8px;
          display: flex;
          gap: 1rem;
        }

        .bullet {
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }

        .bullet.active {
          background: rgba(255, 255, 255,255);
        }

        .bullet.inactive {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
      .scroolView {
        width: 100%;
        max-width: 250px !important;
        overflow-y: hidden !important;
        .style-of-the-scroll-view {
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }
        ::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        min-width: 250px;
        min-height: 250px;
        max-height: 250px;
        border-radius: 4px;
        margin-bottom: 0.375rem;
      }
    }

    p {
      font-size: 0.875rem;
    }
    div {
      margin-top: 0.875rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      strong {
        font-size: 2rem;
        font-weight: 500;
      }

      button {
        background: ${({ theme }) => theme.colors.red};
        width: 3rem;
        height: 3rem;
        border: none;
        border-radius: 50%;
        transition: 0.5s;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          stroke: ${({ theme }) => theme.colors.white};
          font-size: 1.5rem;
        }

        &:hover {
          transition: 0.5s;
          background: ${darken(0.1, '#AA2424')};
        }
      }
    }
  }
`
