import { Container } from './style'
import { SnackData } from '../../../interfaces/SnackData'
import { getFirestoreSnacks } from '../../SnackProduct/buscaItems'
import PropTypes from 'prop-types'
import { doc, updateDoc, deleteDoc } from 'firebase/firestore'
import { db, storage } from '../../../config/firebase'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { ChangeEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FiTrash } from 'react-icons/fi'
import { FirebaseError } from 'firebase/app'

export const AllProducts = () => {
  const [snacks, setSnacks] = useState<SnackData[]>([])
  const [productToDelete, setProductToDelete] = useState<SnackData | null>(null)
  const [isLoading, setIsLoading] = useState(true) // Estado para controlar o carregamento

  const fetchSnacks = async () => {
    try {
      const snacksData = await getFirestoreSnacks();

      const formattedSnacksData = snacksData.map(snack => {
        const formattedPrice = new Intl.NumberFormat('en-CA', {
          style: 'currency',
          currency: 'CAD',
          minimumFractionDigits: 2,
        }).format(Number(snack.price));

        return {
          ...snack,
          price: formattedPrice,
        };
      });

      setSnacks(formattedSnacksData);
      setIsLoading(false);
    } catch (error) {
      console.error('Erro ao obter os lanches:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSnacks();
  }, []);




  const handleSnackUpdate = (updatedSnack: SnackData) => {
    const updatedSnacks = snacks.map((snack) => {
      if (snack.id === updatedSnack.id) {
        return {
          ...snack,
          ...updatedSnack,
        }
      }
      return snack
    })

    setSnacks(updatedSnacks)
  }

  // Resto do código...
  const [isModalOpen, setIsModalOpen] = useState(false) // Altere o valor inicial para false

  const handleSnackDelete = (snackId: string) => {
    const snackToDelete = snacks.find((snack) => snack.id === snackId)
    if (snackToDelete) {
      setProductToDelete(snackToDelete)
      setIsModalOpen(true) // Abre a modal
    }
  }

  const handleDeleteConfirmation = async (confirmed: boolean) => {
    if (confirmed && productToDelete) {
      try {
        const snackDocRef = doc(db, 'produtos', productToDelete.id)

        await deleteDoc(snackDocRef)

        const updatedSnacks = snacks.filter((snack) => snack.id !== productToDelete.id)
        setSnacks(updatedSnacks)

        toast.success('O lanche foi excluído com sucesso')
        console.log('Lanche excluído com sucesso do Firestore')
      } catch (error) {
        if (isFirebaseError(error)) {
          console.error('Erro ao excluir o lanche:', error)
          toast.error('Erro ao excluir o lanche')
        } else {
          console.error('Erro ao excluir o lanche:', error)
          toast.error('Erro ao excluir o lanche')
        }
      }
    }

    setProductToDelete(null)
    setIsModalOpen(false) // Fechar a modal após a exclusão
  }

  // Verificação de tipo personalizada para identificar FirebaseError

  function isFirebaseError(error: any): error is FirebaseError {
    return error && error.code !== undefined
  }

  return (
    <Container>
      <h2 style={{ width: '100%', textAlign: 'center' }}>List of Dishes</h2>
      {/* <Container>
        {snacks.map((snack) => (
          <SnackItem
            key={snack.id}
            snack={snack}
            onUpdate={handleSnackUpdate}
            onDelete={handleSnackDelete}
            setProductToDelete={setProductToDelete}
          />
        ))}
      </Container> */}
      {isLoading ? (
        <p>Carregando...</p> // Renderiza um parágrafo de carregamento enquanto estiver carregando os lanches
      ) : snacks.length === 0 ? (
        // eslint-disable-next-line react/no-unescaped-entities
        <p>You haven't registered any dishes yet.</p> // Renderiza uma mensagem quando não há pratos disponíveis
      ) : (
        <Container>
          {snacks.map((snack) => (
            <SnackItem
              key={snack.id}
              snack={snack}
              onUpdate={handleSnackUpdate}
              onDelete={handleSnackDelete}
              setProductToDelete={setProductToDelete}
            />
          ))}
        </Container>
      )}
      {productToDelete && (
        <div className='confirm-delete'>
          <h2>You are about to delete the {productToDelete.name}</h2>
          <p>Are you sure you want to delete this item?</p>
          <div className='buttons-confirm-delete'>
            <button onClick={() => handleDeleteConfirmation(false)}>No</button>
            <button onClick={() => handleDeleteConfirmation(true)}>Yes</button>
          </div>
        </div>
      )}
    </Container>
  )
}

interface SnackItemProps {
  snack: SnackData
  onUpdate: (updatedSnack: SnackData) => void
  onDelete: (snackId: string) => void
  setProductToDelete: (snack: SnackData | null) => void
}

const updateFirestoreSnack = async (updatedSnack: SnackData) => {
  try {
    const snackDocRef = doc(db, 'produtos', updatedSnack.id)
    const updatedFields = {
      name: updatedSnack.name,
      description: updatedSnack.description,
      price: updatedSnack.price,
      snack: updatedSnack.snack,
      image: updatedSnack.image,
      image2: updatedSnack?.image2,
      image3: updatedSnack?.image3,
      peso: updatedSnack.peso,
      serve: updatedSnack.serve,
    }

    await updateDoc(snackDocRef, updatedFields)
    toast.success('Sua edição foi salva, as mudanças serão refletidas em tempo real')
    console.log('Lanche atualizado com sucesso no Firestore')
  } catch (error) {
    console.error('Erro ao atualizar o lanche no Firestore:', error)
    throw error
  }
}

const SnackItem: React.FC<SnackItemProps> = ({ snack, onUpdate, onDelete, setProductToDelete }) => {
  const [name, setName] = useState(snack.name)
  const [description, setDescription] = useState(snack.description)
  const [price, setPrice] = useState(snack.price.toString())
  const [snackCategory, setSnackCategory] = useState(snack.snack)
  const [peso, setPeso] = useState(snack.peso)
  const [serve, setServe] = useState(snack.serve)
  const [isVisible, setIsVisible] = useState(true)
  const [imageProgress, setImageProgress] = useState<number>(0)
  const [isUploading, setIsUploading] = useState(false)
  const [selectedImage, setSelectedImage] = useState<string>('')
  const [selectedImage2, setSelectedImage2] = useState<string>('')
  const [selectedImage3, setSelectedImage3] = useState<string>('')

  const handleDelete = () => {
    setProductToDelete(snack)
  }

  // const handleSnackImageUpdate = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const imageFile = event.target.files?.[0]

  //   if (imageFile) {
  //     try {
  //       setIsUploading(true) // Inicia o upload da imagem
  //       const imageRef = ref(storage, `images/${imageFile.name}`)
  //       const uploadTask = uploadBytesResumable(imageRef, imageFile)

  //       uploadTask.on(
  //         'state_changed',
  //         (snapshot) => {
  //           const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //           setImageProgress(progress)
  //         },
  //         (error) => {
  //           console.error('Error loading image:', error)
  //           setIsUploading(false) // Finaliza o upload da imagem em caso de erro
  //         },
  //         async () => {
  //           const imageUrl = await getDownloadURL(imageRef)

  //           const updatedSnack: SnackData = {
  //             ...snack,
  //             image: imageUrl,
  //           }

  //           onUpdate(updatedSnack)
  //           updateFirestoreSnack(updatedSnack)

  //           setIsUploading(false) // Finaliza o upload da imagem com sucesso
  //         },
  //       )
  //     } catch (error) {
  //       console.error('Error updating snack image:', error)
  //       setIsUploading(false) // Finaliza o upload da imagem em caso de erro
  //     }
  //   }
  // }

  const handleSnackImageUpdate = async (
    event: React.ChangeEvent<HTMLInputElement>,
    imageType: 'image' | 'image2' | 'image3'
  ) => {
    const imageFile = event.target.files?.[0];

    if (imageFile) {
      try {
        setIsUploading(true); // Inicia o upload da imagem
        const imageRef = ref(storage, `images/${imageFile.name}`);
        const uploadTask = uploadBytesResumable(imageRef, imageFile);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setImageProgress(progress);
          },
          (error) => {
            console.error('Error loading image:', error);
            setIsUploading(false); // Finaliza o upload da imagem em caso de erro
          },
          async () => {
            const imageUrl = await getDownloadURL(imageRef);

            const updatedSnack: SnackData = {
              ...snack,
              [imageType]: imageUrl,
            };

            onUpdate(updatedSnack);
            updateFirestoreSnack(updatedSnack);

            setIsUploading(false); // Finaliza o upload da imagem com sucesso
          }
        );
      } catch (error) {
        console.error('Error updating snack image:', error);
        setIsUploading(false); // Finaliza o upload da imagem em caso de erro
      }
    }
  };


  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.trim();

    let numericPrice = 0;
    if (inputValue !== '') {
      numericPrice = parseFloat(inputValue.replace(/[^0-9]/g, ''));
      numericPrice = isNaN(numericPrice) ? 0 : numericPrice;
    }

    const formattedPrice = new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD',
      minimumFractionDigits: 2,
    }).format(numericPrice / 100);

    setPrice(formattedPrice);
  };

  const handleChangePeso = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.trim();
    const pesoValue = inputValue !== '' ? inputValue : '0';
    setPeso(pesoValue);
  };

  const handleChangeServe = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, '');
    setServe(numericValue);
  };

  const handleInputChange = () => {
    return null
  }

  const handleBlur = async (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const numericPrice = parseFloat(price.replace(/[^0-9]/g, ''))
    const numericPeso = peso.replace(/[^0-9]/g, '');
    const numericServe = parseFloat(serve.replace(/[^0-9.]/g, ''));
    const updatedSnack: SnackData = {
      ...snack,
      name,
      description,
      peso: numericPeso,
      serve: String(numericServe),
      price: String(numericPrice / 100),
      snack: snackCategory,
    }

    onUpdate(updatedSnack)
    updateFirestoreSnack(updatedSnack)
  }

  if (!isVisible) {
    return null
  }

  return (
    <div>
      <div className='container'>
        <div className='images'>
        {/* {snack?.image && <img src={snack.image} alt='Image 2' />}
        {snack?.image2 && <img src={snack.image2} alt='Image 2' />}
        {snack?.image3 && <img src={snack.image3} alt='Image 3' />} */}
         <div className='inputs'>
            <label className='custum-file-upload'>
              {selectedImage ? (
                <img src={selectedImage} />
              ) : (
                <>
                  {snack?.image && <img src={snack.image} alt='Image 1' />}
                </>
              )}
              <input
                type='file'
                name='image'
                onChange={(e) => handleSnackImageUpdate(e, 'image')}
              />
            </label>

            <label className='custum-file-upload'>
              {selectedImage2 ? (
                <img src={selectedImage2} />
              ) : (
                <>
                 {snack?.image2 && <img src={snack.image2} alt='Image 2' />}
                </>
              )}
              <input
                type='file'
                name='image2'
                onChange={(e) => handleSnackImageUpdate(e, 'image2')}
              />
            </label>

            <label className='custum-file-upload'>
              {selectedImage3 ? (
                <img src={selectedImage3} />
              ) : (
                <>
                {snack?.image3 && <img src={snack.image3} alt='Image 3' />}
                </>
              )}
              <input
                type='file'
                name='image3'
                onChange={(e) => handleSnackImageUpdate(e, 'image3')}
              />
            </label>
            </div>
        </div>
        {isUploading && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <span style={{ fontSize: '.8rem', color: 'gray' }}>subindo imagem...</span>{' '}
            <progress value={imageProgress} max={100}></progress>
          </div>
        )}
        <form className='text'>
          {/* {snack.image ? (
            <>
              <span style={{ color: 'gray', fontSize: '.8rem' }}>escolher outra imagem</span>
            </>
          ) : (
            <>
              <span>você não tem nenhuma imagem de logo</span>
            </>
          )} */}
          {/* <div className='box'>
            <label>
              Image:
              <input type='file' name='image' onChange={handleSnackImageUpdate} />
              <FaUpload className='icon-upload' />
            </label>
          </div> */}
          {/* <ListImages /> */}
          <label>
            Name:
            <input
              type='text'
              value={name}
              onChange={(e) => setName(e.target.value)}
              onBlur={handleBlur}
            />
          </label>
          <label>
            Description:
            <input
              type='text'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onBlur={handleBlur}
            />
          </label>
          <label>
            Price:
            <input type='text' value={price} onChange={handleChangePrice} onBlur={handleBlur} />
          </label>
          <label>
            It suits:
            <input
              type='text'
              value={serve}
              onChange={handleChangeServe}
              onBlur={handleBlur}
            />
          </label>
          <label style={{position: 'relative'}}>
            Weight:
            <input
              type='text'
              value={peso}
              onChange={handleChangePeso}
              onBlur={handleBlur}
            />
          <span style={{position: 'absolute', right: 20, color: '#505050', bottom: 10 }}>{parseFloat(peso) >= 1000 ? 'Kg' : 'g'}</span>
          </label>
          <label>
            Category:
            <input
              type='text'
              value={snackCategory}
              onChange={(e) => setSnackCategory(e.target.value.toLowerCase())}
              onBlur={handleBlur}
            />
          </label>
        </form>
        <div className='trash' onClick={handleDelete}>
          <span>delete</span> <FiTrash className='icon' />
        </div>
      </div>
    </div>
  )
}

SnackItem.propTypes = {
  snack: PropTypes.shape({
    id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    image2: PropTypes.string.isRequired,
    image3: PropTypes.string.isRequired,
    snack: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    serve: PropTypes.string.isRequired,
    peso: PropTypes.string.isRequired,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  setProductToDelete: PropTypes.func.isRequired,
}

export default AllProducts
