import { useState, useRef, useEffect, useContext } from 'react'
import { QRcode } from '../../../components/QRcode'
import { AddProduct } from '../../../components/ADM/AddProduct'
import { HeaderADM } from '../../../components/ADM/HeaderADM'
import { Container } from './styles'
import { Outlet } from 'react-router-dom'
import { AuthContext } from '../../../contexts/AuthContext'

export function Adm() {
  const [mesa, setMesa] = useState('https://instagram.com/omariandouglas')
  const [mostrarForm, setMostrarForm] = useState(true)
  const [mostrarQRcode, setMostrarQRcode] = useState(false)
  const [gerarNovoQRcode, setGerarNovoQRcode] = useState(false)

  const { currentUser } = useContext(AuthContext)

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    setMesa(event.target.value)
  }

  function handleGerarQRcode() {
    setMostrarForm(false)
    setGerarNovoQRcode(true)
    setMostrarQRcode(true)
  }

  function handleGerarNovoQRcode() {
    setMesa('')
    setMostrarForm(true)
    setGerarNovoQRcode(false)
    setMostrarQRcode(false)
  }

  return (
    <Container>
      <section>
        <h2>Olá, seja bem-vindo {currentUser && currentUser?.displayName}</h2>
        <p>Esta é sua áre administraiva, aqui você pode editar seus pratos, configurações do site e acompanhar seu desenvolvimento.</p>
      </section>
      <Outlet />
    </Container>
  )
}
