import styled from "styled-components";

interface ContainerProps {
  menuOpen: boolean;
}
export const Container = styled.header<ContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  box-shadow: 0px 2px 5px 0px white;

  margin-bottom: 2rem;

  .logout {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: .5rem;
    border:1px solid ${({theme})=> theme.colors.yellow};
    padding: 0.3rem;
    border-radius: 5px;
  }

  .hamburguer-menu {
    display: none;
  }

  .hamburguer-menu.active {
    display: block;
    z-index: 2000;
  }


  nav {
    flex: 1;
    width: 100%;
    height: 100%;
    z-index: 1000;

    ul {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 1.5rem;
    }

    li {

      a {
        width: fit-content;
        position: relative;
        padding-left: 1.875rem;
        padding-right: 1.875rem;
        display: flex;
        align-items: center;
        gap: 2rem;

        svg {
          fill: ${({ theme }) => theme.colors.white};
          width: 4rem;
          height: 4rem;
          transition: fill 0.3s;
        }

        span {
          font-size: 1rem;
          font-weight: 500;
          transition: color 0.3s;
        }

        &.active {
          &::after {
            content: '';
            position: absolute;
            bottom: -2px;
            background-color: ${({ theme }) => theme.colors.yellow};
            border-radius: 0 5px 5px 0;
          }

          svg {
            fill: ${({ theme }) => theme.colors.yellow};
          }

          span {
            color: ${({ theme }) => theme.colors.yellow};
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .hamburguer-menu {
      display: block;
      font-size: 1.8rem;
      cursor: pointer;
    }

    nav {
      position: absolute;
      top: 60px;
      left: 0;
      display: ${props => props.menuOpen ? 'block' : 'none'};
      background-color: rgba(0, 0, 0, 0.9);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
      height: auto;
      margin: 0;
      ul {
        width: 100%!important;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
      }

      li {
        width: 100%;
        text-align: center;
        padding: 1rem 0;
        a {
          justify-content: center;
        }
      }
    }

    .logout {
      margin-left: 25px;
      width: fit-content!important;
    }
  }

`
