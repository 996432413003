import { createContext, ReactNode, useEffect, useState } from 'react'
import { SnackData } from '../interfaces/SnackData'

import { getBurgers, getDrinks, getIceCreams, getPizzas } from '../services/api'

interface SnackContextProps {
  burgers: SnackData[]
  pizzas: SnackData[]
  drinks: SnackData[]
  iceCreams: SnackData[]
}

interface SnackProviderProps {
  children: ReactNode
}

export const SnackContext = createContext({} as SnackContextProps)

export function SnackProvider({ children }: SnackProviderProps) {
  const [burgers, setBurgers] = useState<SnackData[]>([])
  const [drinks, setDrinks] = useState<SnackData[]>([])
  const [iceCreams, setIceCreams] = useState<SnackData[]>([])
  const [pizzas, setPizzas] = useState<SnackData[]>([])
  const [categoria, setCategoria] = useState<SnackData[]>([])
  useEffect(() => {
    ;(async () => {
      const burgerRequest = await getBurgers()
      setBurgers(burgerRequest)

      const drinksRequest = await getDrinks()
      setDrinks(drinksRequest)

      const iceCreamsRequest = await getIceCreams()
      setIceCreams(iceCreamsRequest)

      const pizzasRequest = await getPizzas()
      setPizzas(pizzasRequest)

      // a froma de chamada acima funciona bem, mas podemos organizar melhor com o método abaixo.
      // try {
      //   const burgerRequest = await getBurgers()
      //   const drinksRequest = await getDrinks()
      //   const iceCreamsRequest = await getIceCreams()
      //   const pizzasRequest = await getPizzas()
      //   //const requests = await [burgerRequest, drinksRequest, iceCreamsRequest, pizzasRequest]
      //   // const [
      //   //   { data: burgerResponse },
      //   //   { data: drinksResponse },
      //   //   { data: iceCreamsResponse },
      //   //   { data: pizzasResponse },
      //   // ] = await Promise.all(requests)

      //   setBurgers(burgerRequest)
      //   setDrinks(drinksRequest)
      //   setIceCreams(iceCreamsRequest)
      //   setPizzas(pizzasRequest)
      // } catch (error) {
      //   console.error(error)
      // }
    })()
  }, [])

  return (
    <SnackContext.Provider value={{ burgers, drinks, iceCreams, pizzas }}>
      {children}
    </SnackContext.Provider>
  )
}
