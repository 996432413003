import { useCart } from '../../../../../hooks/useCart'
import { Container } from './styles'
import { currencyFormat } from '../../../../../helpers/currencyFormat'
import { FaPlus, FaMinus, FaTrashAlt } from 'react-icons/fa'
import { ConfirOrder } from '../../../../../components/ConfirmOrder'

export function TableDesktop() {
  const { cart, removeSnackFromCart, snackCartIncrement, snackCartDecrement } = useCart()
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Plat</th>
            <th>Quantité</th>
            <th>Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {cart.map((item) => (
            <tr key={`${item.snack}-${item.id}`}>
              <td>
                <img src={item.image} alt={item.name} />
              </td>
              <td>
                <h4>{item.name}</h4>
                <span>{currencyFormat(parseFloat(item.price))}</span>
              </td>
              <td>
                <div>
                  <button type='button' onClick={() => snackCartDecrement(item)}>
                    <FaMinus size={20} />
                  </button>
                  <span>{`${item.quantity}`.padStart(2, '0')}</span>
                  <button type='button' onClick={() => snackCartIncrement(item)}>
                    <FaPlus size={20} />
                  </button>
                </div>
              </td>
              <td>
                <h5>{currencyFormat(item.subtotal)}</h5>
              </td>
              <td>
              <button type='button' onClick={()=>removeSnackFromCart(item)}>
                <FaTrashAlt size={20} />
              </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ConfirOrder />
    </Container>
  )
}
