import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;

  .container {
    width: 100%;
    max-width: 400px;
    height: auto;
    margin: 1rem 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.9);
    padding: 0.5rem;
    border-radius: 10px;
    .images {
      display: flex;
      flex-direction: row;
      gap:1rem;
      width: 100%;
      img {
        object-fit: cover;
        width: 33%;
        height: 80px;
        border-radius: 10px;
      }
    }

    .inputs {

width: 100%;
display: flex;
flex-wrap: wrap;
flex-direction: row;
align-items: center;
justify-content: space-evenly;
gap: 1rem;
padding: 1rem 0;

.custum-file-upload {

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
  }
  min-height: 100px;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  align-items: center;
  border: 1px solid #cacaca;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  box-shadow: 0px 48px 35px -48px rgba(0, 0, 0, 0.1);
}

.custum-file-upload .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .icon svg {
  height: 50px;
  fill: rgba(75, 85, 99, 1);
}

.custum-file-upload .text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .text span {
  font-weight: 400;
  color: rgba(75, 85, 99, 1);
  font-size: 0.8rem;
}

.custum-file-upload input {
  display: none;
}
.button-arquive {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px 8px 16px;
  gap: 8px;
  height: 40px;
  width: 128px;
  border: none;
  background: #056bfa27;
  border-radius: 20px;
  cursor: pointer;
}

.lable {
  margin-top: 1px;
  font-size: 19px;
  line-height: 22px;
  color: #056dfa;
  font-family: sans-serif;
  letter-spacing: 1px;
}

.button-arquive:hover {
  background: #056bfa49;
}

.button-arquive:hover .svg-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-8deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(8deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
}

    .trash {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      padding: 0.2rem;
      /* background: lightblue; */
      margin: 0 auto;
      border: none;
      border-radius: 5px;
      width: fit-content;
      color: #ccc;
      &:hover {
        border: 1px solid #ccc;
        font-weight: 600;
        cursor: pointer;
        .icon {
          font-weight: 600;
        }
      }
      span {
        font-size: 1rem;
      }
      .icon {
        color: #ccc;
        font-size: 1.4rem;
      }
    }
    form {
      .box {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: row;
        input {
          position: absolute;
          min-width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 999;
          opacity: 0;
          cursor: pointer;
        }
        label {
          position: relative;
          width: 100%;
          cursor: pointer;
        }
        .icon-upload {
          cursor: pointer;
        }
      }
      width: 100%;
      label {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid rgba(0, 0, 0, 0.8);
        border-radius: 5px;
        padding: 5px;
        margin: 0.3rem 0;
        color: #ccc;
        input {
          width: 70%;
          padding: 0.3rem;
          font-size: 0.8rem;
          font-weight: 500;
          margin-left: 0.3rem;
          outline: none;
          color: rgba(0, 0, 0, 0.7);
          background: #ccc;
          border: none;
          border-radius: 5px;
        }
      }
    }
  }
  .confirm-delete {
    position: fixed;
    top: 50%;
    border-radius: 10px;
    padding: 1rem;
    z-index: 999;
    background: ${({ theme }) => theme.colors.gray700};
    color: #ccc;

    p {
      font-weight: 500;
    }

    .buttons-confirm-delete {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      margin: 2rem 0;
      font-size: 1.5rem;
      button {
        border-radius: 5px;
        padding: 0.3rem;
        background: lightblue;
        border: none;
        opacity: 0.9;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
`
