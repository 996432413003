import { SnackData } from './../interfaces/SnackData';
import { db } from '../config/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { UserData } from '../interfaces/InforUserDataSite';
// busca os produtos da coleção "produtos" no Firebaser
export const getProdutos = async (snack: string): Promise<SnackData[]> => {
  const q = query(collection(db, 'produtos'), where('snack', '==', snack));
  const snapshot = await getDocs(q);
  const pratos: SnackData[] = snapshot.docs.map((doc) => {
    const data = doc.data();
    return Object.assign({ id: doc.id }, data) as SnackData;
  });
  return pratos;
};



// busca os produtos da categoria "hamburgueres" na coleção "produtos" no Firebase
export const getBurgers = async () => {
  const produtos = await getProdutos('burger');
  return produtos;
};

// busca os produtos da categoria "pizzas" na coleção "produtos" no Firebase
export const getPizzas = async () => {
  const produtos = await getProdutos('pizza');
  return produtos;
};

// busca os produtos da categoria "bebidas" na coleção "produtos" no Firebase
export const getDrinks = async () => {
  const produtos = await getProdutos('drink');
  return produtos;
};

// busca os produtos da categoria "sorvetes" na coleção "produtos" no Firebase
export const getIceCreams = async () => {
  const produtos = await getProdutos('ice-cream');
  return produtos;
};

export const getUserInfor = async () => {
  try {
    const userDocs = await getDocs(collection(db, 'infor'));
    const users: UserData[] = [];

    userDocs.forEach((doc) => {
      const userData = doc.data() as UserData;
      users.push(userData);
    });

    return users;
  } catch (error) {
    throw new Error('Error fetching user information: ' + error);
  }
};



// import { SnackData } from './../interfaces/SnackData';
// import axios from 'axios'

// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_BASE_URL,
// })

// export const getBurgers = () => api.get<SnackData[]>('/burgers')
// export const getPizzas = () => api.get<SnackData[]>('/pizzas')
// export const getDrinks = () => api.get<SnackData[]>('/drinks')
// export const getIceCreams = () => api.get<SnackData[]>('/ice-creams')

// export default api
