import React, { useEffect, useState } from 'react';
import { Container } from './styles';
import { FiPlus } from 'react-icons/fi';
import { currencyFormat } from '../../helpers/currencyFormat';
import { SkeletonSnack } from './SkeletonSnack';
import { SnackData } from '../../interfaces/SnackData';
import { useCart } from '../../hooks/useCart';
import ScrollView from '../ScrollView';

interface SnacksProps {
  snacks: SnackData[];
}

export function SnacksProducts({ snacks }: SnacksProps) {
  const { cart, addSnackIntoCart } = useCart();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (snacks.length > 0) {
      setLoading(false);
    }
  }, [snacks]);

  return (
    <Container>
      {loading ? (
        [1, 2, 3, 4].map((n) => <SkeletonSnack key={n} />)
      ) : (
        snacks.map((snack) => {
          const snackExistent = cart.find((item) => item.snack === snack.snack && item.id === snack.id);
          const images = [snack.image, snack.image2, snack.image3].filter(Boolean);

          return (
            <div key={snack.id} className="snack">
              {snackExistent && <span>{snackExistent.quantity}</span>}
              <h2>{snack.name}</h2>
              {/* <img src={snack.image} alt={snack.name} /> */}
              <ScrollView className='scrollView'>
              {images.map((image,index)=>(
                <img key={index} src={image} alt={snack.image} />
              ))}
            </ScrollView>
              <p>{snack.description}</p>
             <div className='servers-weight'>
              <p>{parseFloat(snack.peso) >= 1000 ? `${snack.peso} Kg` : `${snack.peso} g`}</p>
              <p>pour ({snack.serve})personnes</p>
             </div>
              <div>
                <strong>{currencyFormat(parseFloat(snack.price))}</strong>
                <button type="button" onClick={() => addSnackIntoCart(snack)}>
                  <FiPlus />
                </button>
              </div>
            </div>
          );
        })
      )}
    </Container>
  );
}
