import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { getFirestoreSnacks } from '../SnackProduct/buscaItems';
import { SnackData } from '../../interfaces/SnackData';
import { Container } from './styles';
import { useEffect, useState, useRef } from 'react';

export function SelectorCategorys() {
  const [menuOpen, setMenuOpen] = useState(false);
  const [snacks, setSnacks] = useState<SnackData[]>([]);
  const { snack } = useParams<{ snack: string }>();
  const navigate = useNavigate();
  const selectRef = useRef<HTMLSelectElement>(null);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const fetchSnacks = async () => {
      try {
        const snacksData = await getFirestoreSnacks();
        setSnacks(snacksData);
      } catch (error) {
        console.error('Erro ao obter os lanches:', error);
      }
    };

    fetchSnacks();
  }, []);

  const renderSnackOptions = () => {
    const uniqueSnacks: { [key: string]: SnackData } = {};

    snacks.forEach((snack) => {
      uniqueSnacks[snack.snack] = snack;
    });

    return Object.values(uniqueSnacks).map((snack) => (
      <option key={snack.id} value={snack.snack}>
        {snack.snack.toUpperCase()}
      </option>
    ));
  };

  const handleSnackSelection = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSnack = event.target.value;
    if (selectedSnack) {
      const encodedSelectedSnack = encodeURIComponent(selectedSnack);
      const snackUrl = `/${encodedSelectedSnack}`;
      if (snack !== selectedSnack) {
        navigate(snackUrl);
      }
    }
  };

  const handleSnackAlls = () => {
    if (selectRef.current) {
      selectRef.current.value = '';
    }
  };

  return (
    <Container>
      <nav>
        <select ref={selectRef} onChange={handleSnackSelection}>
          <option value="">CATÉGORIE</option>
          {renderSnackOptions()}
        </select>
        <NavLink to="/" onClick={handleSnackAlls}>TOUT VOIR</NavLink>
      </nav>
    </Container>
  );
}
