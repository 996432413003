/* eslint-disable react/no-unescaped-entities */
import { useEffect, useState } from 'react'
import { collection, getDocs, doc, updateDoc, setDoc, query } from 'firebase/firestore'
import { db, storage } from '../../../config/firebase'
import { Container } from './style'
import { UserData } from '../../../interfaces/InforUserDataSite'
import { deleteObject, getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { toast } from 'react-toastify'
import { FaTrash } from 'react-icons/fa'

export const EditConfig = () => {
  const [infor, setInfor] = useState<UserData | null>(null)

  const [deletingImage, setDeletingImage] = useState<string | null>(null)
  const [imageFile, setImageFile] = useState<File | null>(null)
  const [logoFile, setLogoFile] = useState<File | null>(null)

  const [formData, setFormData] = useState<{
    [key: string]: any
  }>({
    name: '',
    image: '',
    about: '',
    logo: '',
    descriptionSite: '',
    email: '',
    whatsapp: '',
    titleSite: '',
    subTitleSite: '',
    instagram: '',
    facebook: '',
    youtube: '',
  })

  useEffect(() => {
    const fetchInfor = async () => {
      try {
        const inforRef = collection(db, 'infor')
        const q = query(inforRef)
        const querySnapshot = await getDocs(q)

        if (!querySnapshot.empty) {
          const docSnapshot = querySnapshot.docs[0]
          const data = docSnapshot.data() as UserData
          setInfor(data)
          setFormData(data)
        }
      } catch (error) {
        console.log(error)
      }
    }

    fetchInfor()
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    if (name === 'image' || name === 'logo') {
      const file = e.target.files?.[0]
      if (file) {
        if (name === 'image') {
          setFormData((prevFormData) => ({
            ...prevFormData,
            image: URL.createObjectURL(file),
          }))
          setImageFile(file)
        } else if (name === 'logo') {
          setFormData((prevFormData) => ({
            ...prevFormData,
            logo: URL.createObjectURL(file),
          }))
          setLogoFile(file)
        }
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }))
    }
  }

  const uploadImage = async (fieldName: string, imageFile: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const storageRef = ref(storage, 'images/' + imageFile.name)
      const uploadTask = uploadBytes(storageRef, imageFile)

      uploadTask
        .then(async (snapshot) => {
          const imageUrl = await getDownloadURL(snapshot.ref)

          if (deletingImage && deletingImage === formData[fieldName]) {
            const previousImageRef = ref(storage, 'images/' + getFileNameFromURL(deletingImage))
            deleteObject(previousImageRef)
              .then(() => {
                setDeletingImage(null)
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  [fieldName]: imageUrl,
                }))
                resolve(imageUrl)
              })
              .catch((error) => {
                reject(error)
              })
          } else {
            setFormData((prevFormData) => ({
              ...prevFormData,
              [fieldName]: imageUrl,
            }))
            resolve(imageUrl)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const handleDeleteImage = async (fieldName: string, imageUrl: string) => {
    try {
      const imageRef = ref(storage, 'images/' + getFileNameFromURL(imageUrl))
      await deleteObject(imageRef)

      if (fieldName === 'image') {
        setFormData((prevFormData) => ({
          ...prevFormData,
          image: '',
        }))
        setImageFile(null)
      } else if (fieldName === 'logo') {
        setFormData((prevFormData) => ({
          ...prevFormData,
          logo: '',
        }))
        setLogoFile(null)
      }

      setDeletingImage(null)
    } catch (error) {
      console.log('Error deleting image:', error)
    }
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    try {
      const inforRef = collection(db, 'infor')
      const q = query(inforRef)
      const querySnapshot = await getDocs(q)

      if (querySnapshot.empty) {
        // Se não houver dados na coleção, cria um novo documento
        await setDoc(doc(inforRef), formData)
      } else {
        // Se houver dados na coleção, atualiza o documento existente
        const docSnapshot = querySnapshot.docs[0]
        const docRef = doc(inforRef, docSnapshot.id)
        await updateDoc(docRef, formData)
      }
      toast.success('Data successfully saved!')
    } catch (error) {
      toast.error('Unable to save data, please try again!')
      console.log(error)
    }
  }

  const getFileNameFromURL = (url: string) => {
    const parts = url.split('%2F')
    const fileNameWithToken = parts[parts.length - 1]
    const fileName = fileNameWithToken.split('?')[0]
    return decodeURIComponent(fileName)
  }

  return (
    <Container>
      <h2>Edit your information</h2>
      <form onSubmit={handleSubmit}>
        <h3>About you</h3>
        <div className='about'>
          <div className='texts'>
            <label htmlFor='name'>Name</label>
            <input
              type='text'
              name='name'
              value={formData.name}
              placeholder='your name here'
              onChange={handleChange}
            />
            <label htmlFor='about'>Tell me a little about you</label>
            <input
              type='text'
              name='about'
              value={formData.about}
              placeholder='write a little about yourself here'
              onChange={handleChange}
            />
            <label htmlFor='instagram'>Inform your instagram</label>
            <input
              type='text'
              name='instagram'
              placeholder='paste your instagram link here'
              value={formData.instagram}
              onChange={handleChange}
            />

            <label htmlFor='facebook'>Inform your facebook</label>
            <input
              type='text'
              name='facebook'
              placeholder='paste your facebook link here'
              value={formData.facebook}
              onChange={handleChange}
            />
            <label htmlFor='youbtube'>Inform your youtube</label>
            <input
              type='text'
              name='youtube'
              placeholder='paste your youtube link here'
              value={formData.youtube}
              onChange={handleChange}
            />
          </div>

          <div className='img'>
            <label htmlFor='image'>Your image</label>
            <img src={formData?.image} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <input
                type='file'
                name='image'
                onChange={(e) => {
                  const file = (e.target as HTMLInputElement).files?.[0]
                  if (file) {
                    uploadImage('image', file)
                      .then((imageUrl) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          image: imageUrl,
                        }))
                      })
                      .catch((error) => {
                        console.log('Error uploading image:', error)
                      })
                  }
                }}
              />
              {formData.image && (
                <span
                  className='button-delete-image'
                  onClick={() => handleDeleteImage('image', formData.image)}
                >
                  delete
                  <FaTrash />
                </span>
              )}
            </div>
            {formData.image ? (
              <>
                <span>choose another image</span>
              </>
            ) : (
              <>
                <span>you don't have any profile picture</span>
              </>
            )}
          </div>
        </div>
        <h3>About the site</h3>

        <div className='about'>
          <div className='texts'>
            <label htmlFor='email'>
              Contact email to display on website <br></br>
              <span>optional</span>
            </label>
            <input
              type='email'
              name='email'
              placeholder='enter your contact email here'
              value={formData.email}
              onChange={handleChange}
            />
            <label htmlFor='titleSite'>
              Site Title <br></br>
              <span>optional</span>
            </label>
            <input
              type='text'
              name='titleSite'
              placeholder='main title here'
              value={formData.titleSite}
              onChange={handleChange}
            />
            <label htmlFor='subTitleSite'>
              Site Sub Title <br></br>
              <span>optional</span>
            </label>
            <input
              type='text'
              name='subTitleSite'
              value={formData.subTitleSite}
              placeholder='secondary title here'
              onChange={handleChange}
            />
            <label htmlFor='phone'>
              WhatsApp to display on website <br></br>
              <span>mandatory</span>
            </label>
            <input
              type='tel'
              placeholder='99 999999999'
              name='whatsapp'
              value={formData.whatsapp}
              onChange={handleChange}
            />
            <label htmlFor='descriptionSite'>
              Describe your website <br></br>
              <span>required for SEO improvement, separate by comma.</span>
            </label>
            <input
              type='text'
              placeholder='ex: decorated cakes, fine sweets, wedding cake, '
              name='descriptionSite'
              value={formData.descriptionSite}
              onChange={handleChange}
            />
            <span style={{ color: 'gray', fontSize: 14 }}>
              this information will appear on the website, if you do not want it to be visible on
              the website, leave it blank, but it is recommended that you fill in all.
            </span>
          </div>

          <div className='img'>
            <label htmlFor='logo'>Your Logo</label>
            <img src={formData?.logo} />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <input
                type='file'
                name='logo'
                onChange={(e) => {
                  const file = e.target.files?.[0]
                  if (file) {
                    uploadImage('logo', file)
                      .then((imageUrl) => {
                        setFormData((prevFormData) => ({
                          ...prevFormData,
                          logo: imageUrl,
                        }))
                      })
                      .catch((error) => {
                        console.log('Error uploading image:', error)
                      })
                  }
                }}
              />

              {formData.logo && (
                <span
                  className='button-delete-image'
                  onClick={() => handleDeleteImage('logo', formData.logo)}
                >
                  delete
                  <FaTrash />
                </span>
              )}
            </div>
            {formData.logo ? (
              <>
                <span>select another image</span>
              </>
            ) : (
              <>
                <span>you don't have any logo image</span>
              </>
            )}
          </div>
        </div>

        <button type='submit'>To save</button>
      </form>
    </Container>
  )
}
