import styled from 'styled-components'

export const Container = styled.main`
  width: 100%;
  min-height: 100vh;
  display: flex;

  > section {
    flex: 1;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    padding: 2rem 1.875rem;

    .logo {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 1rem;
      h2 {
        font-size: 3rem;
        text-align: center;

      }
      .img {
        width: 100%;
        max-width: 300px;
        height: 170px;
        img {
          object-fit: contain;
          width: inherit;
          height: inherit;
        }
      }
    }

    .infors-main {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    @media (max-width: 768px) {
      .logo {
        .texts {
          margin: 0 auto;
        h2 {
          font-size: 2rem;
        }
        h3 {
          text-align: center;
        }
        }
        .img {
          margin: 0 auto;
        }
      }
    }

    @media (max-width: 720px) {
      display: flex;
      flex-direction: column;
      padding-bottom: 8rem;

      img {
        align-self: center;
      }
    }
  }
`
