import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Head } from '../../../components/Head'
import { SnacksProducts } from '../../../components/SnackProduct'
import { getFirestoreSnacks } from '../../../components/SnackProduct/buscaItems'
import { SnackData } from '../../../interfaces/SnackData'
import { Container } from './style'
import { SelectorCategorys } from '../../../components/SelectorCategorys'

export default function Pratos() {
  const [snacks, setSnacks] = useState<SnackData[]>([])
  const { snack: snackParam } = useParams()

  useEffect(() => {
    const fetchSnacks = async () => {
      try {
        const snacksData = await getFirestoreSnacks(snackParam)
        setSnacks(snacksData)
      } catch (error) {
        console.error('Erro ao obter os lanches:', error)
      }
    }

    fetchSnacks()
  }, [snackParam])

  const filteredSnacks = snackParam
    ? snacks.filter((snack) => snack.snack.toUpperCase() === snackParam.toUpperCase())
    : snacks

  return (
    <Container>
      <Head
        title={snackParam ? snackParam : 'All'}
        description={`enjoy our${snackParam}  delicious`}
      />
      <div className='filtro'>
      <SelectorCategorys/>
        {snackParam ? (
          <>
            <p>filtré par: <span>{snackParam}</span></p>
          </>
                ) : <>TOUS LES PRODUITS</>}
      </div>
      <SnacksProducts snacks={filteredSnacks} />
    </Container>
  )
}
