import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;

  form {
    position: relative;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.9);
    padding: 1rem;
    border-radius: 10px;

    h2 {
      margin-bottom: 1rem;
    }
    input {
      margin-bottom: 1rem;
      padding: .5rem;
      border-radius: 5px;
      outline: none;
      border: none;
    }

    button {
      padding: 0.5rem;
      border-radius: 10px;
      border: none;
      background: lightblue;
    }
  }
  .error {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    margin: .5rem 0;
  }
`
