import React, { useContext, useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Container } from './style';
import { auth } from '../../../config/firebase';
import { AuthContext } from '../../../contexts/AuthContext';
import { MdLogout } from 'react-icons/md';
import { GiHamburgerMenu } from 'react-icons/gi';

export const HeaderADM = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const singOut = () => {
    auth.signOut();
    dispatch({ type: 'LOGOUT' });
    navigate('/login-admin');
  };

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(true);
  };

  return (
    <Container menuOpen={menuOpen}>
      <h2>Panel</h2>
      <nav ref={menuRef}>
        <ul>
          <li>
            <NavLink to="/adm/add-products" onClick={handleLinkClick}>
              <span>To Add</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/adm/all-products" onClick={handleLinkClick}>
              <span>All Dish</span>
            </NavLink>
          </li>
          <li>
            <NavLink to='/adm/settings'>
              <span>Settings</span>
            </NavLink>
          </li>
          <li>
            <span className="logout" onClick={singOut}>
            Sign Out <MdLogout />
            </span>
          </li>
        </ul>
        {/* Adicione mais links NavLink conforme necessário */}
      </nav>
      <GiHamburgerMenu
        className={`hamburguer-menu ${menuOpen ? 'activeshow' : ''}`}
        size={25}
        onClick={handleToggleMenu}
      />
    </Container>
  );
};
