import styled from 'styled-components'

export const Container = styled.div`
  padding: 1rem;

  h2 {
    width: 100%;
    max-width: 700px;
    margin: 1rem auto;
  }

  /* .inputFile {
    input {
      width: 138px;
      font-weight: 500;
      @media (max-width: 720px) {
        width: 130px;
      }
    }
  } */

  form {
    box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.9);
    border-radius: 5px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;

    .input-file-dispositivo {
      width: 100%;
      min-width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .inputs {

        width: 90%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        gap: 1rem;
        padding: 1rem 0;

        .custum-file-upload {
          img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 10px;
          }
          min-height: 100px;
          min-width: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 20px;
          cursor: pointer;
          align-items: center;
          border: 1px solid #cacaca;
          background-color: rgba(255, 255, 255, 0.8);
          border-radius: 10px;
          box-shadow: 0px 48px 35px -48px rgba(0, 0, 0, 0.1);
        }

        .custum-file-upload .icon {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .custum-file-upload .icon svg {
          height: 50px;
          fill: rgba(75, 85, 99, 1);
        }

        .custum-file-upload .text {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .custum-file-upload .text span {
          font-weight: 400;
          color: rgba(75, 85, 99, 1);
          font-size: 0.8rem;
        }

        .custum-file-upload input {
          display: none;
        }
        .button-arquive {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 8px 12px 8px 16px;
          gap: 8px;
          height: 40px;
          width: 128px;
          border: none;
          background: #056bfa27;
          border-radius: 20px;
          cursor: pointer;
        }

        .lable {
          margin-top: 1px;
          font-size: 19px;
          line-height: 22px;
          color: #056dfa;
          font-family: sans-serif;
          letter-spacing: 1px;
        }

        .button-arquive:hover {
          background: #056bfa49;
        }

        .button-arquive:hover .svg-icon {
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }

          25% {
            transform: rotate(-8deg);
          }

          50% {
            transform: rotate(0deg);
          }

          75% {
            transform: rotate(8deg);
          }

          100% {
            transform: rotate(0deg);
          }
        }
      }
    }
    .inputs-box {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
      input {
        width: 100%;
        max-width: 100px;
        margin-left: 0.5rem;
      }
    }
    label {
      font-weight: 500;
    }

    input {
      width: 100%;
      padding: 0.5rem;
      margin-bottom: 1rem;
      outline: none;
    }

    textarea {
      margin-bottom: 1rem;
      width: 100%;
      min-height: 60px;
      padding: 0.5rem;
      outline: none;
    }

    select {
      margin-bottom: 1rem;
    }

    button {
      width: 100%;
      background: lightblue;
      border: none;
      padding: 0.5rem;
      border-radius: 10px;
    }
  }

  @media (max-width: 768px) {
    form {
      .inputs-box {
        width: 100%;
        max-width: 100%;
        flex-direction: column;
        justify-content: flex-start !important;

        label {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          input {
            width: 45%;
            min-width: 45%;
          }
        }
      }
    }
  }
`
