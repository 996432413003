/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState } from 'react'
import { Container } from './styled'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../../config/firebase'
import { UserData } from '../../interfaces/InforUserDataSite'
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from 'react-icons/fa'
import { MdOutlineEmail } from 'react-icons/md'
import { TiLocationArrowOutline } from 'react-icons/ti'
import { v4 as uuid4 } from 'uuid'
export const InforUser = () => {
  const [infor, setInfor] = useState<UserData[]>([])
  const key = uuid4()
  useEffect(() => {
    const fetchInfor = async () => {
      try {
        const inforRef = collection(db, 'infor')
        const inforDocs = await getDocs(inforRef)
        const dados: UserData[] = []

        inforDocs.forEach((doc) => {
          const data = doc.data()
          // Faça algo com os dados do documento...
          dados.push(data)
        })
        setInfor(() => dados) // Utilize uma função de atualização do estado
        console.log(dados)
      } catch (error) {
        console.log(error)
      }
    }
    fetchInfor()
  }, [])

  return (
    <Container>
      <div className='center'>
        <div>
          <h2>À propos</h2>
          {infor?.map((infor) => (
            <div key={key} className='about'>
              <div className='img'>
                <img src={infor?.image} />
              </div>
              <div className='text'>
                <div className='text-about'>
                  <h3>{infor.name}</h3>
                  <p>{infor.about}</p>
                </div>
                <h3>
                Suivez-nous <TiLocationArrowOutline />
                </h3>
                <div className='redes-sociais'>
                 {
                  infor.instagram ? <>
                   <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={infor?.instagram}
                  >
                    <FaInstagram size={25} />
                  </a>
                  </> : null
                 }
                 {
                  infor?.facebook ? <>
                   <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={infor?.facebook}
                  >
                    <FaFacebook size={25} />
                  </a>
                  </> : null
                 }
                 {
                  infor.youtube ? <>
                     <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={infor?.youtube}
                  >
                    <FaYoutube size={25} />
                  </a>
                  </> : null
                 }
                </div>
              </div>
            </div>
          ))}
        </div>
        <div>
          <h2>Contact</h2>
          <p>Bonjour, besoin d'aide ?</p>
          {infor.map((userInfo) => (
            <div key={key} className='infors-contact'>
              {userInfo.whatsapp ? (
                <>
                  <a
                    href={`https://api.whatsapp.com/send?phone=+1${userInfo.whatsapp}&text=hello can you help me?`}
                    target='_blank'
                  >
                    <FaWhatsapp size={25} />
                    <span>{userInfo.whatsapp}</span>
                  </a>
                </>
              ) : null}
              {userInfo.email ? (
                <>
                  <a href='#'>
                    <MdOutlineEmail size={25} />
                    <span>{userInfo.email}</span>
                  </a>
                </>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </Container>
  )
}
