import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AppRoutes } from './routes'

import { Theme } from './styles/Theme'
import { GlobalStyle } from './styles/global'
import { Normalize } from 'styled-normalize'
import { SnackProvider } from './contexts/SnackContext'
import { CartProvider } from './contexts/CartContext'
import { AuthContextProvider } from './contexts/AuthContext'

export default function App() {
  return (
      <Theme>
        <SnackProvider>
          <CartProvider>
            <AuthContextProvider>
            <AppRoutes />
            </AuthContextProvider>
            <ToastContainer  autoClose={3000} />
            <GlobalStyle />
            <Normalize />
          </CartProvider>
        </SnackProvider>
      </Theme>
  )
}
