import styled from 'styled-components'


export const Container = styled.div`
  width: 100%;
  nav {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    a {
      min-width: 100px;
      background: lightblue;
      font-weight: 300;
      border-radius: 5px;
      padding: .2rem;
      font-size: .8rem;
      border: none;
      color: black;
    }

    select {
      outline: none;
      border: none;
    }
  }


`
