import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from '../../config/firebase'
import { SnackData } from '../../interfaces/SnackData'

export const getFirestoreSnacks = async (snackParam?: string): Promise<SnackData[]> => {
  try {
    const productsCollection = collection(db, 'produtos')
    let snacksQuery = query(productsCollection)

    if (snackParam !== undefined) {
      const snackParamLower = snackParam.toLowerCase()
      snacksQuery = query(productsCollection, where('snack', '==', snackParamLower))
    }

    const snapshot = await getDocs(snacksQuery)

    const snacks: SnackData[] = []
    snapshot.forEach((doc) => {
      const snack: SnackData = {
        id: doc.id,
        snack: doc.data().snack,
        name: doc.data().name,
        description: doc.data().description,
        price: doc.data().price,
        image: doc.data().image,
        image2: doc.data().image2,
        image3: doc.data().image3,
        peso: doc.data().peso,
        serve: doc.data().serve
      }
      snacks.push(snack)
    })

    return snacks
  } catch (error: any) {
    throw new Error('Erro ao obter os lanches do Firestore: ' + error.message)
  }
}
