import { useEffect, useState } from 'react'
import { useCart } from '../../hooks/useCart'
import { currencyFormat } from '../../helpers/currencyFormat'
import { Container } from './styles'
import striptags from 'striptags'
import { getUserInfor } from '../../services/api'
import { UserData } from '../../interfaces/InforUserDataSite'

export function ConfirOrder() {
  const { cart } = useCart()
  const [nameClient, setNameClient] = useState('')
  const totalAmount = cart.reduce((acc, item) => (acc += item.subtotal), 0)

  const data = new Date().toLocaleString('en-CA', { timeZone: 'America/Toronto' })

  const [userInformation, setUserInformation] = useState<UserData[]>([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await getUserInfor()
        setUserInformation(users)
      } catch (error) {
        console.log('Error fetching user information:', error)
      }
    }

    fetchData()
  }, [])

  function handleFinalizarPedido() {
    const itemsList = cart
      .map(
        (item) =>
          `${item.snack}: *${item.name}* | quantity: *${
            item.quantity
          }* | subtotal: *${currencyFormat(
            item.subtotal,
          )}*\n--------------------------------------------------\n`,
      )
      .join('')

    const message = `
      *Nouvel ordre* \n client:*${nameClient}*
      ${data}\n
      \n${itemsList}\n
      \nMontant: *${currencyFormat(totalAmount)}*`

    const plainTextMessage = striptags(message)

    // const url = `${userInformation
    //   .map((item) => `https://web.whatsapp.com/send?phone=+1${item.whatsapp}`)
    //   .join('&')}&text=${encodeURIComponent(plainTextMessage)}`
    // setNameClient('')
    // window.open(url)
    const isMobile = /Android|iPhone/i.test(navigator.userAgent)
    const isDesktop = !isMobile

    let url = ''

    if (isMobile) {
      // Abre o aplicativo no dispositivo móvel
      url = userInformation
        .map(
          (item) =>
            `whatsapp://send?phone=+1${item.whatsapp}&text=${encodeURIComponent(plainTextMessage)}`,
        )
        .join(';')
    } else if (isDesktop) {
      // Abre no navegador do computador
      url = userInformation
        .map(
          (item) =>
            `https://web.whatsapp.com/send?phone=+1${item.whatsapp}&text=${encodeURIComponent(
              plainTextMessage,
            )}`,
        )
        .join('&')
    }

    setNameClient('')
    window.open(url)
  }

  return (
    <Container onSubmit={handleFinalizarPedido}>
      <label htmlFor='nameClient'>Entrez votre nom pour identification</label>
      <input
        required
        type='text'
        name='nameClient'
        placeholder='votre nom ici'
        value={nameClient}
        onChange={(e) => setNameClient(e.target.value)}
      />

      <button type='submit'>Finaliser la commande</button>

      <span>
        total <strong>{currencyFormat(totalAmount)}</strong>
      </span>
    </Container>
  )
}
