import { collection, getDocs } from "firebase/firestore"
import { useEffect, useState } from "react"
import { db } from "../../config/firebase"
import { UserData } from "../../interfaces/InforUserDataSite"
import { v4 as uuid4 } from 'uuid'
interface HeadProps {
  title: string
  description?: string
}
export function Head({title, description = ''}: HeadProps){
  const [infor, setInfor] = useState<UserData[]>([])

  const key = uuid4()

  useEffect(() => {
    const fetchInfor = async () => {
      try {
        const inforRef = collection(db, 'infor')
        const inforDocs = await getDocs(inforRef)
        const dados: UserData[] = []

        inforDocs.forEach((doc) => {
          const data = doc.data()
          // Faça algo com os dados do documento...
          dados.push(data)
        })
        setInfor(() => dados) // Utilize uma função de atualização do estado
      } catch (error) {
        console.log(error)
      }
    }

    fetchInfor()
  }, [])

  document.title = `${title.toUpperCase()} -  ${infor.map((infor)=> (
    infor.titleSite ? infor.titleSite.toUpperCase() : ''
  ))} `

  document.querySelector('[name=description]')?.setAttribute('content', description)
  return null
}
