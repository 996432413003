import styled from 'styled-components'

export const Container = styled.div`
  padding: 2rem 1rem;

  h2 {
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 700px;
    margin: 2rem auto;
    box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.8);
    padding: 1rem;
    border-radius: 10px;
    .button-delete-image {
      display: flex;
      align-items:center;
      gap: 3px;
      padding: 4px;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        color: red!important;
      }
    }
    .about {
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      margin: 1rem 0;
      gap: 1rem;
      border: 1px solid gray;
      padding: .5rem;
      border-radius: 5px;
      .texts {
        width: 45%;
        display: flex;
        flex-direction: column;

        label {
          margin-top: .5rem;
          font-weight: 500;

          span {
            font-size: .8rem;
            color: gray;
          }
        }

        input {
          background: #e7e7e7;
          color: gray;
          font-weight: 500;
          outline: none;
          border: none;
          padding: 0.5rem;
          border-radius: 5px;
          margin-bottom: 1rem;
        }
      }
    }

    .img {
      position: relative;
      margin-top: .5rem;
      width: 100%;
      max-width: 45%;
      height: 100%;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.3);
      padding: 1rem;
      input {
        width: 100%;
        max-width: 130px;
      }
      img {
        object-fit: cover;
        padding: 1rem;
        width: 100%;
      }

      span {

        font-size: .8rem;
        color: gray;
        margin-top: .3rem;

      }
    }

    h3 {
      margin-top: 2rem;
    }

    button {
      width: 70%;
      background: lightblue;
      color: black;
      font-weight: 500;
      padding: .5rem;
      border: none;
      border-radius: 10px;
      margin: 0 auto;
    }
  }

  @media (max-width: 820px) {

    form {
      width: 100%;
      max-width: 100%;
      .about {
        .texts {
          width: 100%;
          max-width: 100%;
        }
        .img {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
`
