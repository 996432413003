import { Button, Container } from './styles'
interface EmptyCartProps {
  title: string
}
export function EmptyCart({ title }: EmptyCartProps) {
  return (
    <Container>
      <h2>{title}</h2>
      <Button to='/'>Voir la carte</Button>
    </Container>
  )
}
