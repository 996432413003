import styled from 'styled-components'

export const Container = styled.div`
  margin: 2rem 0;
  h2 {
    color: #e7e7e7;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .center {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
    box-shadow: 1px 2px 5px 1px rgba( 0,0,0,.9 );
    padding: 1rem 0;
    .infors-contact {
      display: flex;
      flex-direction: column;
      a{
        display: flex;
        flex-direction: row;
        gap: .5rem;
        align-items: center;
        padding: .5rem 0;
      }
    }
  }
  .about {
    width: 100%;
    max-width: 300px;
    color: #e7e7e7;
    .img {
      width: 100%;
      max-width: 280px;
      height: 280px;
      img {
        object-fit: cover;
        width: inherit;
        height: inherit;
      }
    }
    .text {
      h3 {
        font-size: 1.2rem;
        padding: .5rem 0;
      }
      .text-about {
        h3 {
          font-size: 1.2rem;
          padding: .5rem 0;
        }
      }
    }
    .redes-sociais {
      display: flex;
      flex-direction: row;
      width: inherit;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      margin-bottom: 2rem;
      a:hover {
        transform: translateY(-2px);
        color: white;
      }
    }

  }

  @media (max-width: 768px) {
    width: 100%;
    .center {
      justify-content: flex-start;
      box-shadow: none;
      .about {
        width: 100%;
        max-width: 100%;
        .img {
          width: 100%;
          img {
            min-width: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
`
