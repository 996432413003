import React, { createContext, useContext, useEffect, useReducer } from "react";
import PropTypes from 'prop-types';
import { AuthReducer } from './AuthReducer'
import { Navigate, useNavigate } from "react-router-dom";

const user = localStorage.getItem("user");
const INITIAL_STATE = {
  currentUser: user ? JSON.parse(user) : null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  const updateUser = (userData) => {
    dispatch({ type: "UPDATE_USER", payload: userData });
    dispatch({ type: "UPDATE_CURRENT_USER", payload: userData });
  };

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(state.currentUser));
  }, [state.currentUser]);

  const authContextValue = {
    currentUser: state.currentUser,
    dispatch: dispatch,
    updateUser: updateUser,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function RequireAuth({ children }) {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      navigate("/login-admin");
    }
  }, [currentUser, navigate]);

  return currentUser ? <>{children}</> : null;
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};
