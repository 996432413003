import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'
import MainPage from './pages/Main'
// import Burgers from './pages/Main/Burgers'
// import Drinks from './pages/Main/Drinks'
// import IceCreams from './pages/Main/IceCreams'
// import Pizzas from './pages/Main/Pizzas'
import MyCart from './pages/Main/MyCart'
import { Adm } from './pages/Main/Adm'
import Pratos from './pages/Main/Pratos'
import { ReactNode, useContext } from 'react'
import { AuthContext, AuthContextProvider } from './contexts/AuthContext'
import { LoginADM } from './pages/Main/Adm/LoginADM'
import AllProducts from './components/ADM/AllProducts'
import { HeaderADM } from './components/ADM/HeaderADM'
import { AddProduct } from './components/ADM/AddProduct'
import { EditConfig } from './components/ADM/EditConfi/intex'

interface LayoutProps {
  children: ReactNode
}
const LayoutAdm = ({ children }: LayoutProps) => {
  return (
    <>
      <HeaderADM />
      {children}
    </>
  )
}

export function AppRoutes() {
  const { currentUser } = useContext(AuthContext)
  const RequireAuth = ({ children }: LayoutProps) => {
    return currentUser ? <LayoutAdm>{children}</LayoutAdm> : <Navigate to='/login-admin' />
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<MainPage />}>
          <Route index element={<Pratos />} /> {/* Rota raiz sem parâmetros */}
          <Route path='/:snack' element={<Pratos />} /> {/* Rota com o parâmetro 'snack' */}
        </Route>
        <Route path='cart' element={<MyCart />} />

        <Route
          path='adm'
          element={
            <RequireAuth>
              <Adm />
            </RequireAuth>
          }
        />

        <Route
          path='/adm/all-products'
          element={
            <RequireAuth>
              <AllProducts />
            </RequireAuth>
          }
        />

        <Route
          path='/adm/add-products'
          element={
            <RequireAuth>
              <AddProduct />
            </RequireAuth>
          }
        />

        <Route
          path='/adm/settings'
          element={
            <RequireAuth>
              <EditConfig  />
            </RequireAuth>
          }
        />

        <Route path='login-admin' element={currentUser ? <Navigate to='/adm' /> : <LoginADM />} />
      </Routes>
    </BrowserRouter>
  )
}
