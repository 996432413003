import React, { useRef, useState } from "react";
import { Container } from "./style";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

interface ScrollViewProps {
  className?: string;
  title?: string;
}

function ScrollView({ children, className, title }: React.PropsWithChildren<ScrollViewProps>) {
  const scrollViewRef = useRef<HTMLDivElement>(null);
  const [currentImage, setCurrentImage] = useState(0);

  function handleItemClick(e: React.MouseEvent<HTMLDivElement>) {
    const itemWidth = e.currentTarget.clientWidth;
    const scrollView = scrollViewRef.current;
    if (scrollView) {
      const scrollLeft = scrollView.scrollLeft;
      const { left } = e.currentTarget.getBoundingClientRect();
      scrollView.scrollTo({
        left: scrollLeft + (left - scrollView.offsetLeft),
        behavior: "smooth",
      });
    }
  }

  function handlePrevClick() {
    const scrollView = scrollViewRef.current;
    if (scrollView && currentImage > 0) {
      const itemWidth =
        scrollView.querySelector("div > div > div")?.clientWidth || 0;
      scrollView.scrollTo({
        left: scrollView.scrollLeft - itemWidth,
        behavior: "smooth",
      });
      setCurrentImage(currentImage - 1);
    }
  }

  function handleNextClick() {
    const scrollView = scrollViewRef.current;
    if (scrollView && currentImage < React.Children.count(children) - 1) {
      const itemWidth =
        scrollView.querySelector("div > div > div")?.clientWidth || 0;
      scrollView.scrollTo({
        left: scrollView.scrollLeft + itemWidth,
        behavior: "smooth",
      });
      setCurrentImage(currentImage + 1);
    }
  }

  const showNavigationButtons = React.Children.count(children) > 1;

  return (
    <Container className="ContainerScrollView">
      {title && <h2>{title}</h2>}
      <div
        ref={scrollViewRef}
        className={className}
        style={{ whiteSpace: "nowrap", position: "relative" }}
      >
        {React.Children.toArray(children)[currentImage]}
      </div>
      {showNavigationButtons && (
        <div className="buttons">
          <FaArrowLeft onClick={handlePrevClick} size={20} />
          <div className="bullets">
            {React.Children.map(children, (_, index) => (
              <div
                key={index}
                className={`bullet ${index === currentImage ? "active" : "inactive"}`}
                onClick={() => setCurrentImage(index)}
              />
            ))}
          </div>
          <FaArrowRight onClick={handleNextClick} size={20} />
        </div>
      )}
    </Container>
  );
}

export default ScrollView;
