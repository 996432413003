import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth'
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBmA-ONE2RrJ4T_ZxFHLy-smpOh1dZOezA",
  authDomain: "fabianamaia-d919f.firebaseapp.com",
  projectId: "fabianamaia-d919f",
  storageBucket: "fabianamaia-d919f.appspot.com",
  messagingSenderId: "561453727787",
  appId: "1:561453727787:web:af809718d77b4e72f36a6e"
};

// Initialize Firebase

export const app = initializeApp(firebaseConfig);
export const auth = getAuth()
export const storage = getStorage(app)
export const db = getFirestore(app)
