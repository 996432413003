import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react'
import { Container } from './style'
import { SnackData } from '../../../interfaces/SnackData'
import { db, storage } from '../../../config/firebase'
import { collection, addDoc, getDocs, query, setDoc, doc } from 'firebase/firestore'
import { ref, uploadBytes, getDownloadURL, listAll } from 'firebase/storage'
import { currencyFormat } from '../../../helpers/currencyFormat'
import { toast } from 'react-toastify'
import { MdClose } from 'react-icons/md'

export const AddProduct = () => {
  const [showBoxImages, setShowBoxImages] = useState(false)
  const [imageUrls, setImageUrls] = useState<string[]>([])
  const [selectedImage, setSelectedImage] = useState<string>('')
  const [selectedImage2, setSelectedImage2] = useState<string>('')
  const [selectedImage3, setSelectedImage3] = useState<string>('')

  const [snacks, setSnacks] = useState<SnackData[]>([])
  const [newCategory, setNewCategory] = useState(false)

  const [formData, setFormData] = useState<SnackData>({
    id: '',
    snack: '',
    name: '',
    description: '',
    image: '', // Aqui é onde você armazena a primeira imagem
    image2: '', // Nova propriedade para a segunda imagem
    image3: '', // Nova propriedade para a terceira imagem
    price: '0',
    peso: '0',
    serve: '1',
  })

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const storageRef = ref(storage, 'images') // Diretório no Storage que contém as imagens
        const imageRefs = await listAll(storageRef) // Recupera a lista de referências das imagens

        const urls: string[] = []

        // Obtém a URL de download de cada imagem e adiciona na lista de URLs
        await Promise.all(
          imageRefs.items.map(async (itemRef) => {
            const url = await getDownloadURL(itemRef)
            urls.push(url)
          }),
        )

        setImageUrls(urls)
      } catch (error) {
        console.log(error)
      }
    }

    fetchImages()
  }, [])

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoryRef = collection(db, 'produtos')
        const categoryQuery = query(categoryRef)
        const categoryDocs = await getDocs(categoryQuery)

        const snackNames: string[] = []
        const categoriesArray: SnackData[] = []

        for (const doc of categoryDocs.docs) {
          const snack = doc.data().snack
          if (!snackNames.includes(snack)) {
            snackNames.push(snack)
            categoriesArray.push({
              id: doc.id,
              snack,
              name: doc.data().name,
              description: doc.data().description,
              image: doc.data().image,
              image2: doc.data().image2,
              image3: doc.data().image3,
              price: doc.data().price,
              serve: doc.data().serve,
              peso: doc.data().peso,
            })
          }
        }

        setSnacks(categoriesArray)
      } catch (error) {
        console.log(error)
      }
    }

    fetchCategories()
  }, [])

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputName: string,
  ) => {
    const { name, value } = e.target
    const imageFile = (e.target as HTMLInputElement).files?.[0]

    if (inputName === 'selectedImage') {
      if (imageFile) {
        if (imageFile) {
          uploadImage(imageFile)
            .then((imageUrl) => {
              setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: imageUrl,
              }))
              setSelectedImage(imageUrl)
            })
            .catch((error) => {
              console.log('Error uploading image:', error)
            })
        }
      }
    } else if (inputName === 'selectedImage2') {
      if (imageFile) {
        uploadImage(imageFile).then((imageUrl) => {
          setSelectedImage2(imageUrl)
        })
      }
    } else if (inputName === 'selectedImage3') {
      if (imageFile) {
        uploadImage(imageFile).then((imageUrl) => {
          setSelectedImage3(imageUrl)
        })
      }
    } else if (name === 'price') {
      //     // Handling price input
      const numericValue = value.replace(/[^0-9]/g, '')
      const formattedValue = new Intl.NumberFormat('en-CA', {
        style: 'currency',
        currency: 'CAD',
        minimumFractionDigits: 2,
      }).format(Number(numericValue) / 100)

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: String(Number(numericValue) / 100), // Atualiza o valor numérico do campo `price` como uma string
      }))

      //     // Atualiza o valor formatado do input `price`
      document.getElementById('price')?.setAttribute('value', formattedValue)
    } else if (name === 'peso') {
      // Handling weight input
      const numericValue = Number(value.replace(/[^0-9]/g, ''))
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: numericValue.toString(), // Convertendo para string antes de atualizar o campo `weight`
      }))

      // Atualiza o valor formatado do input `weight`
      const pesoElement = document.getElementById('peso') as HTMLInputElement
      if (pesoElement) {
        const startPosition = pesoElement.selectionStart
        const endPosition = pesoElement.selectionEnd

        pesoElement.value = numericValue.toString()

        //       // Restaura a posição do cursor
        pesoElement.setSelectionRange(startPosition, endPosition)
      }
      const lowercaseValue = value.toLowerCase()
      setFormData((prevData) => ({
        ...prevData,
        [name]: lowercaseValue,
      }))
    } else if (name === 'serve') {
      // Handling serve input
      const numericValue = Number(value.replace(/[^0-9]/g, ''))
      let formattedValue = ''

      if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
        formattedValue = String(numericValue)
      }

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: formattedValue, // Atualiza o valor do campo `serve` como uma string formatada
      }))
    } else {
      //     // Handling other inputs
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }))
    }
  }

  const uploadImage = async (imageFile: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const storageRef = ref(storage, 'images/' + imageFile.name)
      const uploadTask = uploadBytes(storageRef, imageFile)

      uploadTask
        .then(async (snapshot) => {
          const imageUrl = await getDownloadURL(snapshot.ref)
          resolve(imageUrl)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target

    if (value === 'createCategory') {
      setNewCategory(true)
      setFormData((prevFormData) => ({
        ...prevFormData,
        snack: '',
      }))
    } else {
      setNewCategory(false)
      setFormData((prevFormData) => ({
        ...prevFormData,
        snack: value,
      }))
    }
  }

  const addProduct = async (productData: SnackData) => {
    try {
      const docRef = await addDoc(collection(db, 'produtos'), productData)
      const docId = docRef.id
      toast.success('Produto adicionado com sucesso!')
      console.log('Produto adicionado com ID: ', docId)

      // Retorna o ID do documento criado
      return docId
    } catch (error) {
      toast.error('Não foi possível criar o produto!')
      console.log('Erro ao adicionar o produto: ', error)
      return null
    }
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()

    // Use a imagem selecionada da galeria, se houver
    const selectedImageUrl = selectedImage || formData.image
    const selectedImageUrl2 = selectedImage2 || formData.image2
    const selectedImageUrl3 = selectedImage3 || formData.image3

    if (formData.snack === '') {
      toast.error(`Por favor, selecione uma categoria`)
      return
    }

    if (formData.name === '') {
      toast.error(`Por favor, dê um título ao produto`)
      return
    }
    if (formData.description === '') {
      toast.error(`Por favor, descreva seu produto`)
      return
    }
    if (formData.peso === '0') {
      toast.error(`O peso precisa ser maior que 0`)
      return
    }
    if (formData.serve === '0') {
      toast.error(`Por favor, informe quantas porções há`)
      return
    }
    if (formData.price === '0') {
      toast.error(`Por favor, dê preço ao produto`)
      return
    }

    setFormData((prevFormData) => ({
      ...prevFormData,
      image: selectedImageUrl,
      image2: selectedImageUrl2,
      image3: selectedImageUrl3,
    }));

    // Adicione os novos campos de imagens ao objeto de dados do produto
    const productDataWithImages = {
      ...formData,
      image: selectedImageUrl,
      image2: selectedImageUrl2,
      image3: selectedImageUrl3,
    }

    // Adicione o novo produto à coleção "produtos" no Firestore
    const productId = await addProduct(productDataWithImages)

    if (productId) {
      try {
        // Atualiza o campo 'id' do documento com o ID gerado
        await setDoc(doc(db, 'produtos', productId), { id: productId }, { merge: true })

        // Atualiza o campo 'id' no objeto 'formData'
        setFormData((prevFormData) => ({
          ...prevFormData,
          id: productId,
        }))

        // Limpa os campos após o envio
        setFormData({
          id: '',
          snack: '',
          name: '',
          description: '',
          image: '',
          image2: '',
          image3: '',
          price: '0',
          peso: '0',
          serve: '1',
        })
        setSelectedImage('')
        setSelectedImage2('')
        setSelectedImage3('')
      } catch (error) {
        console.log('Erro ao atualizar o campo "id" do documento: ', error)
      }
    }
  }

  const handleImageClick = (imageUrl: string) => {
    if (!selectedImage) {
      setSelectedImage(imageUrl)
    } else if (!selectedImage2) {
      setSelectedImage2(imageUrl)
    } else if (!selectedImage3) {
      setSelectedImage3(imageUrl)
    }
    setShowBoxImages(false) // Feche a caixa de imagens após selecionar uma imagem
  }


  const handleToogle = () => {
    if (!showBoxImages) {
      setShowBoxImages(true)
    } else {
      setShowBoxImages(false)
    }
  }

  return (
    <Container>
      <h2>To add Dish</h2>
      <form onSubmit={handleSubmit}>
        <div
          className='box-images'
          style={{
            zIndex: 1000,
            width: '98%',
            height: '98%',
            display: showBoxImages ? 'block' : 'none',
          }}
        >
          <div
            className='container'
            style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
              width: 'inherit',
              height: 'inherit',
              overflowX: 'auto',
              background: 'rgba(0,0,0,.9)',
            }}
          >
            <div
              className='text'
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                gap: '1rem',
                position: 'sticky',
                top: 0,
                padding: '1rem .5rem',
                background: 'gray',
              }}
            >
              <div
                className='inputFile'
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  gap: '1rem',
                  flexWrap: 'wrap',
                }}
              >
                <div className='box'>
                  <h4>Image Bank</h4>
                </div>
              </div>

              {/* <div className='box'>
                <p>
                  Espaço
                  <br></br>
                  <span>Total: 5gb</span>
                  <br></br>
                  <span>Usado: 5gb</span>
                  <br></br>
                  <span>Livre: 0gb</span>
                </p>
              </div>
              <div className='box'>
                <p>
                  Imagens <br></br>
                  <span>Total: 130 imagens</span>
                  <br></br>
                </p>
              </div> */}
              <MdClose
                style={{
                  position: 'absolute',
                  right: '.5rem',
                  cursor: 'pointer',
                  background: 'red',
                }}
                size={20}
                onClick={handleToogle}
              />
            </div>

            {imageUrls.map((imagens, index) => (
              <img
                key={index}
                src={imagens}
                style={{
                  width: 150,
                  height: 150,
                  objectFit: 'cover',
                  margin: '.5rem',
                  cursor: 'pointer',
                }}
                onClick={() => handleImageClick(imagens)}
              />
            ))}
          </div>
        </div>

        <label>Select image</label>
        <div className='input-file-dispositivo'>
          <div className='inputs'>
            <label className='custum-file-upload'>
              {selectedImage ? (
                <img src={selectedImage} />
              ) : (
                <>
                  <div className='text'>
                    <span>First Image</span>
                  </div>
                </>
              )}
              <input
                type='file'
                name='image'
                onChange={(e) => handleInputChange(e, 'selectedImage')}
              />
            </label>

            <label className='custum-file-upload'>
              {selectedImage2 ? (
                <img src={selectedImage2} />
              ) : (
                <>
                  <div className='icon'>
                    <svg xmlns='http://www.w3.org/2000/svg' fill='' viewBox='0 0 24 24'>
                      <g strokeWidth='0' id='SVGRepo_bgCarrier'></g>
                      <g
                        strokeLinejoin='round'
                        strokeLinecap='round'
                        id='SVGRepo_tracerCarrier'
                      ></g>
                      <g id='SVGRepo_iconCarrier'>
                        {' '}
                        <path
                          fill=''
                          d='M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z'
                          clipRule='evenodd'
                          fillRule='evenodd'
                        ></path>{' '}
                      </g>
                    </svg>
                  </div>
                  <div className='text'>
                    <span>+</span>
                  </div>
                </>
              )}
              <input
                type='file'
                name='image2'
                onChange={(e) => handleInputChange(e, 'selectedImage2')}
              />
            </label>

            <label className='custum-file-upload'>
              {selectedImage3 ? (
                <img src={selectedImage3} />
              ) : (
                <>
                  <div className='icon'>
                    <svg xmlns='http://www.w3.org/2000/svg' fill='' viewBox='0 0 24 24'>
                      <g strokeWidth='0' id='SVGRepo_bgCarrier'></g>
                      <g
                        strokeLinejoin='round'
                        strokeLinecap='round'
                        id='SVGRepo_tracerCarrier'
                      ></g>
                      <g id='SVGRepo_iconCarrier'>
                        {' '}
                        <path
                          fill=''
                          d='M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z'
                          clipRule='evenodd'
                          fillRule='evenodd'
                        ></path>{' '}
                      </g>
                    </svg>
                  </div>
                  <div className='text'>
                    <span>+</span>
                  </div>
                </>
              )}
              <input
                type='file'
                name='image3'
                onChange={(e) => handleInputChange(e, 'selectedImage3')}
              />
            </label>

            <span className='button-arquive' onClick={handleToogle}>
              <svg className='svg-icon' width='24' viewBox='0 0 24 24' height='24' fill='none'>
                <g
                  strokeWidth='2'
                  strokeLinecap='round'
                  stroke='#056dfa'
                  fillRule='evenodd'
                  clipRule='evenodd'
                >
                  <path d='m3 7h17c.5523 0 1 .44772 1 1v11c0 .5523-.4477 1-1 1h-16c-.55228 0-1-.4477-1-1z'></path>
                  <path d='m3 4.5c0-.27614.22386-.5.5-.5h6.29289c.13261 0 .25981.05268.35351.14645l2.8536 2.85355h-10z'></path>
                </g>
              </svg>
              <span className='lable'>Gallery</span>
            </span>
          </div>
        </div>

        <label htmlFor='snack'>Category</label>
        <select name='snack' id='snack' value={formData.snack} onChange={handleSelectChange}>
          <option value=''>Select a category</option>
          <option value='createCategory'>Create new Category</option>
          {snacks.map((snack) => (
            <option key={snack.id} value={snack.snack}>
              {snack.snack}
            </option>
          ))}
        </select>

        {newCategory && (
          <input
            type='text'
            name='snack'
            placeholder='write the new category here'
            value={formData.snack === 'createCategory' ? '' : formData.snack}
            onChange={(e) => handleInputChange(e, 'snack')}
          />
        )}

        <label>
          Dish name:
          <input
            type='text'
            name='name'
            placeholder='enter the name of the dish here'
            value={formData.name}
            onChange={(e) => handleInputChange(e, 'name')}
          />
        </label>

        <label>
          Dish description:
          <textarea
            name='description'
            autoFocus
            placeholder='Enter the description of the dish eg: Portion of beef with onions on the plate.'
            value={formData.description}
            onChange={(e) => handleInputChange(e, 'description')}
          ></textarea>
        </label>

        <div className='inputs-box'>
          <label style={{ position: 'relative' }}>
            Weight
            <input
              type='text'
              name='peso'
              id='peso'
              value={formData.peso}
              onChange={(e) => handleInputChange(e, 'peso')}
            />
            <span style={{ position: 'absolute', right: 20, color: '#505050', bottom: 25 }}>
              {parseFloat(formData.peso) >= 1000 ? 'Kg' : 'g'}
            </span>
          </label>
          <label>
            How many people does it serve?
            <input
              type='text'
              name='serve'
              id='serve'
              value={formData.serve}
              onChange={(e) => handleInputChange(e, 'serve')}
            />
          </label>
          <label>
            Dish price:
            <input
              type='text'
              name='price'
              id='price'
              value={currencyFormat(parseFloat(formData.price))}
              onChange={(e) => handleInputChange(e, 'price')}
            />
          </label>
        </div>

        <button type='submit'>To add</button>
      </form>
    </Container>
  )
}
