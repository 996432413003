import { Outlet } from 'react-router-dom'
import { Container } from './styles'
import { MyOrder } from '../../components/MyOrder'
import { InforUser } from '../../components/InforUser'
import { useEffect, useState } from 'react'
import { getUserInfor } from '../../services/api'
import { UserData } from '../../interfaces/InforUserDataSite'
import {v4 as uuid4 } from 'uuid'

export default function Main() {
  const currentYear = new Date().getFullYear().toString()
  const [userInformation, setUserInformation] = useState<UserData[]>([])
  const key = uuid4();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await getUserInfor()
        setUserInformation(users)
      } catch (error) {
        console.log('Error fetching user information:', error)
      }
    }

    fetchData()
  }, [])

  return (
    <Container>
      <MyOrder />
      <section style={{ padding: '2rem' }}>
        {userInformation.map((userInfo) => (
          <div key={key} className='logo'>
            <div className='img'>
              <img src={userInfo.logo} alt={userInfo.descriptionSite} />
            </div>
            <div className='texts'>
              <h2 color='white'>{userInfo.titleSite}</h2>
              <h3>{userInfo.subTitleSite}</h3>
            </div>
          </div>
        ))}
        {/* <SelectorCategorys/> */}
        <Outlet />

        <InforUser />
        <footer>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 5,
              textAlign: 'center',
              justifyContent: 'center',
              margin: '1rem 0',
            }}
          >
            {userInformation.map((userInfo) => (
              <p key={key}>{userInfo.titleSite} - </p>
            ))}
            <p>Tous les droits sont réservés - {currentYear}</p>
          </div>
          <p style={{textAlign: 'center'}}>by - StarsCode</p>
        </footer>
      </section>
    </Container>
  )
}
