import styled from "styled-components";

export const Container = styled.div`
    .filtro {
      display: flex;
      gap: 1rem;
      width:100%;
      max-width: 300px;
      margin: 2rem auto;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      p {
        color: #ccc;
        span {
          font-weight: 500;
          text-transform: uppercase;
        }
      }
    }
`
