import { Link } from 'react-router-dom'
import { Container } from './styles'
import { useCart } from '../../hooks/useCart'
import { FiShoppingCart } from 'react-icons/fi'
import { FaArrowLeft } from 'react-icons/fa'

export function OrderHeader() {
  const { cart } = useCart()

  return (
    <Container>
      <Link to='/'>
        <FaArrowLeft /> Menu
      </Link>
      <div>
        <div>
          <h3>Ma demande</h3>
          <span>
            <strong>{`${cart.length}`.padStart(2, '0')} plat</strong>
          </span>
        </div>
        <FiShoppingCart size={40} />
      </div>
    </Container>
  )
}
